import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import MainVisual from "../components/main-visual"
import Button from "../components/button"
import cx from "clsx"

import { Formik, Form, Field, ErrorMessage } from "formik"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import Recaptcha from "react-recaptcha"

const ContactUsPage = () => {
  const [isLoading, setIsLoading] = React.useState(false)

  const recaptchaRef = React.useRef(null)

  const MySwal = withReactContent(Swal)

  return (
    <Layout>
       <MainVisual
        title="Contact Us"
        description="Send us your message"
      >
        <StaticImage src="../images/contact-main-visual.jpg" className="w-full" alt="main visual"  />
      </MainVisual>
      <section className="relative bg-light py-8 md:py-20 overflow-hidden">
        <div className="container mx-auta">
          <div className="relative bg-white rounded-2xl p-8 max-w-2xl w-full z-20 mx-auto">
            <p className="mb-4">
              Staying up to date with the pace of a consistently changing digital landscape has made updating one’s digital infrastructure more necessary than ever before.
            </p>
            <p className="mb-4">
            DPV offers a comprehensive range of tech-based solutions for your business-related problems. Whether you aim to overhaul your company’s digital infrastructure, or simply seek to upgrade your data management capabilities, DPV is ready to cater to your needs.
            </p>
            <p className="mb-10 text-xl text-primary font-semibold">
              Contact us now.
            </p>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                company: '',
                message: '',
                recaptcha: '',
              }}
              onSubmit={(values, actions) => {
                setIsLoading(true)

                fetch('https://api.digiplacelab.com/send-email', {
                  method: "POST",
                  headers: {"Content-Type": "application/json"},
                  body: JSON.stringify(values)
                })
                .then(() => {
                  MySwal.fire({
                    title: <strong>Thank you</strong>,
                    html: 'Email has been sent successfully',
                    icon: 'success'
                  })
                  actions.resetForm()
                  actions.setFieldValue('recaptcha', '')
                  recaptchaRef.current.reset()
                  setIsLoading(false)
                })
                .catch((err) => {
                  MySwal.fire({
                    title: <strong>Failed</strong>,
                    html: err,
                    icon: 'error'
                  })
                  setIsLoading(false)
                })
                .finally(() => actions.setSubmitting(false))
              }}
              validate={values => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const errors = {};
                if(!values.name) {
                  errors.name = 'Name Required'
                } 
                if(!values.email || !emailRegex.test(values.email)) {
                  errors.email = 'Valid Email Required'
                }
                if(!values.phone) {
                  errors.phone = 'Phone Required'
                }
                if(!values.company) {
                  errors.company = 'Company Name Required'
                } 
                if(!values.message) {
                  errors.message = 'Message Required'
                }
                if(!values.recaptcha) {
                  errors.recaptcha = 'Captcha Required'
                }
                return errors;
              }}
            >
              {({setFieldValue}) => (
                  <Form>
                    <div className="mb-8">
                      <label className="font-bold mb-1">Name</label>
                      <Field
                        type="text"
                        name="name"
                        className={cx(
                          'px-3 py-2 bg-light w-full border',
                          'border-gray-300 focus:border-primary focus:ring-0',
                          'placeholder:text-gray-400'
                        )}
                        placeholder="Type in your full name"
                      />
                      <ErrorMessage name="name" component="div" className="pt-2 text-sm text-red-500" />
                    </div>
                    <div className="mb-8">
                      <label className="font-bold mb-1">Email</label>
                      <Field
                        type="text"
                        name="email"
                        className={cx(
                          'px-3 py-2 bg-light w-full border',
                          'border-gray-300 focus:border-primary focus:ring-0',
                          'placeholder:text-gray-400'
                        )}
                        placeholder="Type in your email"
                      />
                      <ErrorMessage name="email" component="div" className="pt-2 text-sm text-red-500" />
                    </div>
                    <div className="mb-8">
                      <label className="font-bold mb-1">Phone</label>
                      <Field
                        type="text"
                        name="phone"
                        className={cx(
                          'px-3 py-2 bg-light w-full border',
                          'border-gray-300 focus:border-primary focus:ring-0',
                          'placeholder:text-gray-400'
                        )}
                        placeholder="Type in your mobile phone / office phone"
                      />
                      <ErrorMessage name="phone" component="div" className="pt-2 text-sm text-red-500" />
                    </div>
                    <div className="mb-8">
                      <label className="font-bold mb-1">Company</label>
                      <Field
                        type="text"
                        name="company"
                        className={cx(
                          'px-3 py-2 bg-light w-full border',
                          'border-gray-300 focus:border-primary focus:ring-0',
                          'placeholder:text-gray-400'
                        )}
                        placeholder="Company name"
                      />
                      <ErrorMessage name="company" component="div" className="pt-2 text-sm text-red-500" />
                    </div>
                    {/* <div className="mb-8">
                      <label className="font-bold mb-1">Business Category</label>
                      <select
                        className={cx(
                          'px-3 py-2 bg-light w-full border',
                          'border-gray-300 focus:border-primary focus:ring-0',
                          'placeholder:text-gray-400'
                        )}
                        placeholder="Select business category"
                      >
                        <option>-</option>
                      </select>
                    </div> */}
                    <div className="mb-8">
                      <label className="font-bold mb-1">Message</label>
                      <Field
                        component="textarea"
                        name="message"
                        className={cx(
                          'px-3 py-2 bg-light w-full border',
                          'border-gray-300 focus:border-primary focus:ring-0',
                          'placeholder:text-gray-400 resize-none'
                        )}
                        placeholder="Your message"
                        rows={6}
                      />
                      <ErrorMessage name="message" component="div" className="pt-2 text-sm text-red-500" />
                    </div>
                    <div className="mb-8">
                      <Recaptcha
                        ref={recaptchaRef}
                        sitekey="6LfmogUjAAAAABDOep1qUgq6_d2q4jKqAMyMLt4a"
                        // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        render="explicit"
                        verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                      />
                      <ErrorMessage name="recaptcha" component="div" className="pt-2 text-sm text-red-500" />
                    </div>
                    <Button type="submit">{isLoading ? 'Sending' : 'Submit'}</Button>
                  </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="absolute top-0 left-0 right-0 mx-auto max-w-3xl">
          <StaticImage src="../images/home-section-bg-1.png" alt="section" />
        </div>
      </section>
      <section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7643478578884!2d103.84172191589809!3d1.31699846204416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19e809b61127%3A0xb6c94dbaa47b54e6!2sUnited%20Square%20Office%20Tower!5e0!3m2!1sen!2ssg!4v1681204270595!5m2!1sen!2ssg"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="100%"
          height={288}
        />
      </section>
      <section className="relative bg-primary py-8 md:py-20">
        <div className="relative container z-10 text-center">
          <div className="text-white text-2xl md:text-4xl mb-2">Our Office</div>
          <div className="text-white md:text-lg">
          Digital Place Ventures Pte. Ltd. <br />
          (Company Registration Number 201602676Z) <br />
          Registered Office Address: <br />
          101 Thomson Road<br />
          #31-01 United Square<br />
          Singapore 307591
          </div>
        </div>
        <div className="absolute top-0 left-0 right-0 mx-auto text-center">
          <StaticImage src="../images/home-section-bg-2.png" alt="section"  />
        </div>
      </section>
    </Layout>
  )
}

export default ContactUsPage

export const Head = () => (
  <>
    <title>Contact DPV via Email</title>
    <meta name="description" content="Contact DPV via email - we look forward to hearing from you!" />
  </>
)


